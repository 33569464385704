@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  /* height: 90px; */
  padding-top: 20px;
  padding-bottom: 30px;
  background: rgb(67, 94, 128);
  background: linear-gradient( 
    30deg
    , rgba(5, 41, 87, 1) -86%, #ffc107 126%, #ffc107 100%, rgb(11 90 188) 100%, rgb(6 46 95) 100% ) !important;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none;
    border-radius: .25rem;
}

.content{
    margin-top: 38px;
    margin-left: 18px;
}

.headercontent{
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,46,95,1) 35%, rgba(6,145,220,1) 100%);
    /* margin-left: 480px; */
    margin: 0 auto 0;
    /* margin-right: 480px; */
    margin-top: 20px;
}

.card-header {
    padding: .7rem 2rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.card-header:first-child {
    border-radius: 8px;
}

.form-control {
    width: 100%;
    height: 45px;
    border-radius: 15px;
    padding: 0 20px;
    box-sizing: border-box;
    outline: none;
    font-family: "Roboto",sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    mix-blend-mode: normal;
    box-shadow: 1px 0 8px 0 rgb(222 226 230 / 39%);
}

.secondarybutton{
padding-left: 25px;
padding-top: 8px;
padding-bottom: 8px;
padding-right: 25px;
}

.btn-secondary {
    color: #fff;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,46,95,1) 35%, rgba(6,145,220,1) 100%);
    border-color: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,46,95,1) 35%, rgba(6,145,220,1) 100%);
}

.btn-secondary:hover {
    color: #fff;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,46,95,1) 35%, rgba(6,145,220,1) 100%);
    border-color: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,46,95,1) 35%, rgba(6,145,220,1) 100%);
}

.btn-check:active+.btn-secondary, .btn-check:checked+.btn-secondary, .btn-secondary.active, .btn-secondary:active, .show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,46,95,1) 35%, rgba(6,145,220,1) 100%);
    border-color: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,46,95,1) 35%, rgba(6,145,220,1) 100%);
}

.btn-check:active+.btn-secondary:focus, .btn-check:checked+.btn-secondary:focus, .btn-secondary.active:focus, .btn-secondary:active:focus, .show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,46,95,1) 35%, rgba(6,145,220,1) 100%);
}

.imageclass{
     
       
    position: absolute;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100%;
    background-size: cover;
    overflow: hidden;
      
}

.card-body{
    color: #e9ecefba;
    padding-left: 16px;
    padding-right: 16px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
}

.opacityclass{
    
    background-color: #000000b0;
    /* opacity: 0.5; */
    padding-top: 25px;
    padding-bottom: 10px;

    /* opacity: 0.5; */
}

.h5, h5 {
    font-size: 16px;
}

.titlecenter{
    text-align: center;
}

.p12{
    padding-top: 22px;
}

/* .p13{
    padding-top: 35px;
} */



@media screen and (max-width: 991px) {
    .classbutton{
   margin-top: 30px;
   margin-left: 5px;
  }
}

@media screen and (max-width: 991px) {
    .classinnercontainer{
        text-align: center;
        margin-left: 20px;
  }
}


@media screen and (max-width: 991px) {
    .innerclasscontainer{
        text-align: left;
        margin-left: 40px;
  }
}

@media screen and (max-width: 991px) {
    .uppertextfield{
      
        margin-right: 18px;
  }
}


