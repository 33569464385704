body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  /* height: 90px; */
  padding-top: 20px;
  padding-bottom: 30px;
  background: rgb(67, 94, 128);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(5, 41, 87, 1)),
    color-stop(68%, rgba(7, 74, 134, 1)),
    color-stop(100%, rgba(6, 147, 223, 1)),
    color-stop(100%, rgba(81, 178, 230, 1)),
    to(rgb(0, 196, 203))
  ) !important;
  background: -o-linear-gradient(
    left,
    rgba(5, 41, 87, 1) 0%,
    rgba(7, 74, 134, 1) 68%,
    rgba(6, 147, 223, 1) 100%,
    rgba(81, 178, 230, 1) 100%,
    rgb(0, 196, 203) 100%
  ) !important;
  background: linear-gradient( 
    30deg
    , rgba(5, 41, 87, 1) -86%, #ffc107 126%, #ffc107 100%, rgb(11 90 188) 100%, rgb(6 46 95) 100% ) !important;
}
